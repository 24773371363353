import { default as apisjbdOkUNabuMeta } from "/usr/src/app/pages/apis.vue?macro=true";
import { default as appstp5JYdCGGwMeta } from "/usr/src/app/pages/apps.vue?macro=true";
import { default as endpointsrd3SUdjZdSMeta } from "/usr/src/app/pages/endpoints.vue?macro=true";
import { default as hostsZF3LI6YSYGMeta } from "/usr/src/app/pages/hosts.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as createiR8BSHwR1qMeta } from "/usr/src/app/pages/issues/campaigns/create.vue?macro=true";
import { default as indexjbkzkg1JNeMeta } from "/usr/src/app/pages/issues/campaigns/index.vue?macro=true";
import { default as reportIZWGukbXffMeta } from "/usr/src/app/pages/issues/campaigns/report.vue?macro=true";
import { default as categoriesdiHCb3UxnnMeta } from "/usr/src/app/pages/issues/categories.vue?macro=true";
import { default as definitions0SCfcUkhJEMeta } from "/usr/src/app/pages/issues/definitions.vue?macro=true";
import { default as vulnerabilitiesfBlXbkKAneMeta } from "/usr/src/app/pages/issues/vulnerabilities.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as reportsGnbsVrkMI7Meta } from "/usr/src/app/pages/reports.vue?macro=true";
import { default as accountQ3jdMmuVdVMeta } from "/usr/src/app/pages/settings/account.vue?macro=true";
import { default as apikeysHu6hI9lPl9Meta } from "/usr/src/app/pages/settings/apikeys.vue?macro=true";
import { default as auditlogoc4tL5pHoAMeta } from "/usr/src/app/pages/settings/auditlog.vue?macro=true";
import { default as flagsYQ8BCGstfhMeta } from "/usr/src/app/pages/settings/flags.vue?macro=true";
import { default as domainskNjUhWF7OKMeta } from "/usr/src/app/pages/settings/monitors/domains.vue?macro=true";
import { default as index6LlpQVcxQXMeta } from "/usr/src/app/pages/settings/monitors/index.vue?macro=true";
import { default as log_45forwardersnhhQ8Z5ZX8Meta } from "/usr/src/app/pages/settings/monitors/log-forwarders.vue?macro=true";
import { default as organizationsYN6ODdUIQCMeta } from "/usr/src/app/pages/settings/organizations.vue?macro=true";
import { default as usersKkKKMNcZ0gMeta } from "/usr/src/app/pages/settings/users.vue?macro=true";
import { default as domainsNHS1raSBkNMeta } from "/usr/src/app/pages/setup/domains.vue?macro=true";
export default [
  {
    name: "apis",
    path: "/apis",
    component: () => import("/usr/src/app/pages/apis.vue").then(m => m.default || m)
  },
  {
    name: "apps",
    path: "/apps",
    component: () => import("/usr/src/app/pages/apps.vue").then(m => m.default || m)
  },
  {
    name: "endpoints",
    path: "/endpoints",
    component: () => import("/usr/src/app/pages/endpoints.vue").then(m => m.default || m)
  },
  {
    name: "hosts",
    path: "/hosts",
    meta: hostsZF3LI6YSYGMeta || {},
    component: () => import("/usr/src/app/pages/hosts.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "issues-campaigns-create",
    path: "/issues/campaigns/create",
    component: () => import("/usr/src/app/pages/issues/campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: "issues-campaigns",
    path: "/issues/campaigns",
    component: () => import("/usr/src/app/pages/issues/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "issues-campaigns-report",
    path: "/issues/campaigns/report",
    component: () => import("/usr/src/app/pages/issues/campaigns/report.vue").then(m => m.default || m)
  },
  {
    name: "issues-categories",
    path: "/issues/categories",
    component: () => import("/usr/src/app/pages/issues/categories.vue").then(m => m.default || m)
  },
  {
    name: "issues-definitions",
    path: "/issues/definitions",
    component: () => import("/usr/src/app/pages/issues/definitions.vue").then(m => m.default || m)
  },
  {
    name: "issues-vulnerabilities",
    path: "/issues/vulnerabilities",
    component: () => import("/usr/src/app/pages/issues/vulnerabilities.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/usr/src/app/pages/reports.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "/settings/account",
    component: () => import("/usr/src/app/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-apikeys",
    path: "/settings/apikeys",
    component: () => import("/usr/src/app/pages/settings/apikeys.vue").then(m => m.default || m)
  },
  {
    name: "settings-auditlog",
    path: "/settings/auditlog",
    component: () => import("/usr/src/app/pages/settings/auditlog.vue").then(m => m.default || m)
  },
  {
    name: "settings-flags",
    path: "/settings/flags",
    meta: flagsYQ8BCGstfhMeta || {},
    component: () => import("/usr/src/app/pages/settings/flags.vue").then(m => m.default || m)
  },
  {
    name: "settings-monitors-domains",
    path: "/settings/monitors/domains",
    meta: domainskNjUhWF7OKMeta || {},
    component: () => import("/usr/src/app/pages/settings/monitors/domains.vue").then(m => m.default || m)
  },
  {
    name: "settings-monitors",
    path: "/settings/monitors",
    component: () => import("/usr/src/app/pages/settings/monitors/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-monitors-log-forwarders",
    path: "/settings/monitors/log-forwarders",
    meta: log_45forwardersnhhQ8Z5ZX8Meta || {},
    component: () => import("/usr/src/app/pages/settings/monitors/log-forwarders.vue").then(m => m.default || m)
  },
  {
    name: "settings-organizations",
    path: "/settings/organizations",
    meta: organizationsYN6ODdUIQCMeta || {},
    component: () => import("/usr/src/app/pages/settings/organizations.vue").then(m => m.default || m)
  },
  {
    name: "settings-users",
    path: "/settings/users",
    meta: usersKkKKMNcZ0gMeta || {},
    component: () => import("/usr/src/app/pages/settings/users.vue").then(m => m.default || m)
  },
  {
    name: "setup-domains",
    path: "/setup/domains",
    meta: domainsNHS1raSBkNMeta || {},
    component: () => import("/usr/src/app/pages/setup/domains.vue").then(m => m.default || m)
  }
]